// import { throttle, debounce } from 'throttle-debounce';

// import "./front/components/navigation";

if (document.querySelector("body").classList.contains("page-template-supplier-registration")) {

  //**
  // Add new meal field
  //

  const addFormToCollection = (e) => {
    const collectionHolder = document.querySelector('.' + e.currentTarget.dataset.collectionHolderClass);

    const item = document.createElement('div');
    item.className ='meal o-grid o-grid--narrow o-grid-meal';
    item.innerHTML = collectionHolder
      .dataset
      .prototype
      .replace(
        /__name__/g,
        collectionHolder.dataset.index
      );

    collectionHolder.appendChild(item);

    collectionHolder.dataset.index++;

    addFormDeleteLink(item);
    // add new eventlisterners to new options
    watchPriceChange();
  };

  const addFormDeleteLink = (item) => {
    const removeFormButton = document.createElement('button');
    removeFormButton.className ="c-button c-button--delte";
    removeFormButton.innerText = 'Supprimer cette personne';

    item.append(removeFormButton);

    removeFormButton.addEventListener('click', (e) => {
        e.preventDefault();
        item.remove();
        // delte melas from order
        calculePrice();
    });
  }

  document
    .querySelectorAll('.add_item_link')
    .forEach(btn => {
        btn.addEventListener("click", addFormToCollection)
    });

  document
    .querySelectorAll('.meals .meal')
    .forEach((meal) => {
        addFormDeleteLink(meal)
    });





  //**
  // autocomplet
  //

  let container = document.querySelector("datalist");
  let fournisseurData = suppliers;
  let datalist = document.querySelector("#fournisseur");
  let input = document.querySelector(".c-input--datalist");
  let standInput = document.querySelector(".c-form__input-stand");
  let resumeBox= document.querySelector(".c-resume");

  let stand_9= false;
  let stand_18= false;
  let stand_27= false;
  let stand_36= false;
  let type= "";
  let selectedStandOption= "";

  for (let i = 0; i < fournisseurData.length; i++) {
    // create datalist option
    const newOption = document.createElement("div");
    newOption.setAttribute("class", "option");
    newOption.textContent = fournisseurData[i].name;
    newOption.setAttribute("value", fournisseurData[i].name);
    newOption.setAttribute("data-stand-9", fournisseurData[i].stand_9);
    newOption.setAttribute("data-stand-18", fournisseurData[i].stand_18);
    newOption.setAttribute("data-stand-27", fournisseurData[i].stand_27);
    newOption.setAttribute("data-stand-36", fournisseurData[i].stand_36);
    newOption.setAttribute("id", fournisseurData[i].id);
    newOption.setAttribute("type", fournisseurData[i].type);
    container.innerHTML = container.innerHTML + newOption.outerHTML;
  }

  // get all new options
  let datalistOptions = document.querySelectorAll(".option");

  input.onfocus = function () {
    datalistOptions.forEach(function(option) {
      // hide datalist on focus
      option.style.display = "none";
      option.setAttribute("tabindex", "-1");
      option.className=" option";
    })
  };

  datalistOptions.forEach(function(option) {
    option.onclick = function () {
      // update selected option variables
      input.value = option.getAttribute("value");
      document.querySelector(".c-input--datalist-resultat").value = option.id;


      stand_9= option.getAttribute("data-stand-9");
      stand_18= option.getAttribute("data-stand-18");
      stand_27= option.getAttribute("data-stand-27");
      stand_36= option.getAttribute("data-stand-36");

      type=option.getAttribute("type");

      // close datalist
      datalist.style.display = 'none';
      input.style.borderRadius = "5px";
      document.querySelector(".c-input--datalist").setAttribute("aria-expanded", "false");
      updateStandOption();

    }
  });

  input.oninput = function() {
    // show datalist
    datalist.style.display ="block";
    datalist.style.display = 'block';
    input.style.borderRadius = "5px 5px 0 0";

    currentFocus = -1;

    var text = input.value.toUpperCase();

    datalistOptions.forEach(function(option) {
      let valueUppercase = option.getAttribute("value").toUpperCase();

      if(valueUppercase.indexOf(text) > -1){
        option.style.display = "block";
        const searchText = input.value;
        option.className=" option option--active";
        option.setAttribute("tabindex", "0");

        // show options just after the 3eme character
        if (input.value.length < 3){
          document.querySelector("datalist").style.display="none";
          document.querySelector(".c-input--datalist").setAttribute("aria-expanded", "false");
        }else {
          document.querySelector("datalist").style.display="block";
          document.querySelector(".c-input--datalist").setAttribute("aria-expanded", "true");
        }
    }else{
      option.style.display = "none";
      option.setAttribute("tabindex", "-1");
      option.className=" option";
      }
    });
  }

  var currentFocus = -1;

  // add navigation by arrow
  input.onkeydown = function(e) {
    if(e.keyCode == 40){
      currentFocus++
    addActive();
    }
    else if(e.keyCode == 38){
      currentFocus--
    addActive(datalistOptions);
    }
    else if(e.keyCode == 13){
      e.preventDefault();
          if (currentFocus > -1) {
            let optionFilterd = document.querySelectorAll(".option--active");

            // update selected option variables
            input.value=optionFilterd[currentFocus].getAttribute("value");
            document.querySelector(".c-input--datalist-resultat").value = optionFilterd[currentFocus].getAttribute("id");

            stand_9= optionFilterd[currentFocus].getAttribute("data-stand-9");
            stand_18= optionFilterd[currentFocus].getAttribute("data-stand-18");
            stand_27= optionFilterd[currentFocus].getAttribute("data-stand-27");
            stand_36= optionFilterd[currentFocus].getAttribute("data-stand-36");
            type= optionFilterd[currentFocus].getAttribute("type");

            // clode datalist
            datalist.style.display ="none";
            document.querySelector(".c-input--datalist").setAttribute("aria-expanded", "false");

            updateStandOption();
          }
    }
  }

  if (input.getAttribute("value") !== "") {
    selectedStandOption = standInput.options[standInput.selectedIndex].value;
    var needle = input.getAttribute("value");
    for (var i = 0; i < fournisseurData.length; i++){
      if (fournisseurData[i].name == needle){
        stand_9= fournisseurData[i].stand_9;
        stand_18= fournisseurData[i].stand_18;
        stand_27= fournisseurData[i].stand_27;
        stand_36= fournisseurData[i].stand_36;
        type=fournisseurData[i].type;

        updateStandOption();

        if(selectedStandOption) {
          let selectStand = document.querySelectorAll(".c-form__input-stand option");
          for (let i = 0; i < selectStand.length; i++) {
            const element = selectStand[i];
            if (element.getAttribute("value") == selectedStandOption) {
              element.setAttribute("selected","selected")
            }

          }
        }
      }
    }
    calculePrice();
  }else {
    createStandOption();
  }



  function createStandOption() {
    resetOptions()
  }

  function resetOptions() {
    // delte stand options
    standInput.innerHTML="";
    // add default value
    const newStandOption = document.createElement('option');
    newStandOption.innerText = 'Sélectionner une taille';
    standInput.append(newStandOption);
  }

  function createOption(standSize, prix) {

    // create stand options pour selected furnisseur
    const newStandOption = document.createElement('option');
    newStandOption.setAttribute("value", standSize);
    newStandOption.setAttribute("data-price", prix);
    newStandOption.innerText = standSize +'m² | '+ prix + "€" ;
    standInput.append(newStandOption);

  }

  function updateStandOption() {
    resetOptions();


    if (stand_9.toString() == "true") {
      if(type=="ALLISCA"){
        createOption(9,"2600")
      }else {
        createOption(9,"2300")
      }
    }
    if (stand_18.toString() == "true") {
      if(type=="ALLISCA"){
        createOption(18,"4000")
      }else {
        createOption(18,"3550")
      }
    }
    if (stand_27.toString() == "true") {
      if(type=="ALLISCA"){
        createOption(27,"4250")
      }else {
        createOption(27,"3900")
      }
    }

    if (stand_36.toString() == "true") {
      if(type=="ALLISCA"){
        createOption(36,"4500");
      }else {
        createOption(36,"4300");
      }
    }

  }

  // visiual feedback on datalist active
  function addActive() {
    let optionFilterd = document.querySelectorAll(".option--active");
    if (optionFilterd.length === 0) return false;

    removeActive(optionFilterd);

    if (currentFocus >= optionFilterd.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (optionFilterd.length - 1);
    optionFilterd[currentFocus].classList.add("active");
  }

  function removeActive(optionFilterd) {
    for (var i = 0; i < optionFilterd.length; i++) {
      optionFilterd[i].classList.remove("active");
    }
  }

  watchPriceChange();


  function watchPriceChange(){

    let inputPrix = document.querySelectorAll("[data-price]");
    let selectStand = document.querySelector(".c-form__input-stand");
    let prixTotal = 0;


    // recalc if input change
    for (let index = 0; index < inputPrix.length; index++) {
      const element = inputPrix[index];
      element.addEventListener('change', (event) => {
        calculePrice(inputPrix)
      })
    }

    // recalc on stand change
    selectStand.addEventListener('change', (event) => {
      calculePrice(inputPrix);
    })
  }



  function calculePrice(inputPrix){
    // reset all resume
    let resumeStandContent="";
    let resumeMeubleContent="";
    let resumeCheckboxContent="";
    let resumeMealContent= "";

    inputPrix = document.querySelectorAll("[data-price]");
    let inputQuantityPrix = document.querySelectorAll("[data-price-quantity]");

     // reset all prixes and counter
    let prixTotal = 0;
    let mealCounterDejeunerMercredi = 0;
    let mealCounterDinner = 0;
    let mealCounterDejeunerjeudi = 0;

    // Checkbox listenr
    for (let index1 = 0; index1 < inputPrix.length; index1++) {
      const element1 = inputPrix[index1];
      if (element1.checked) {
        prixTotal=  prixTotal + parseInt(element1.getAttribute("data-price"));
        if(element1.getAttribute("data-price")>0) {
          if (element1.getAttribute("data-meal")) {
            if (element1.getAttribute("data-meal") ==="Déjeuner mercredi") {
              mealCounterDejeunerMercredi=mealCounterDejeunerMercredi+1;
            }else if(element1.getAttribute("data-meal") ==="Diner mercredi"){
              mealCounterDinner=mealCounterDinner+1;
            }else {
              mealCounterDejeunerjeudi=mealCounterDejeunerjeudi+1;
            }
            resumeMealContent = '<div class="c-resume__line"><p>'+mealCounterDejeunerMercredi+" Déjeuner mercredi (32€&nbsp;l'unité)</p><p>" + (32 * mealCounterDejeunerMercredi) + '€</p></div><div class="c-resume__line"><p>'+mealCounterDinner+" Diner mercredi (55€&nbsp;l'unité)</p><p>" + (55 * +mealCounterDinner) + '€</p></div><div class="c-resume__line"><p>'+mealCounterDejeunerjeudi+" Déjeuner jeudi (32€&nbsp;l'unité)</p><p>" + (32 * mealCounterDejeunerjeudi) + '€</p></div>';

          }else {
            resumeCheckboxContent = resumeCheckboxContent + '<div class="c-resume__line"><p>' + element1.getAttribute("data-label") + '</p><p>' + element1.getAttribute("data-price") + '€</p></div>';
          }
        }
      }
    }

    // stand
    let selectedStand = document.querySelector(".c-form__input-stand").value;
    if (selectedStand == 9) {
      if(type=="ALLISCA"){
        prixTotal=  prixTotal + 2600;
        resumeStandContent='<div class="c-resume__line"><p>Stand 9m²</p><p>2600€</p></div>'
      }else {
      prixTotal=  prixTotal + 2300;
      resumeStandContent='<div class="c-resume__line"><p>Stand 9m²</p><p>2300€</p></div>'}
    }
    if (selectedStand == 18) {
      if(type=="ALLISCA"){
        prixTotal=  prixTotal + 4000;
        resumeStandContent='<div class="c-resume__line"><p>Stand 18m²</p><p>4000€</p></div>'
      }else {
      prixTotal=  prixTotal + 3550;
      resumeStandContent='<div class="c-resume__line"><p>Stand 18m²</p><p>3550€</p></div>'}
    }
    if (selectedStand == 27) {
      if(type=="ALLISCA"){
        prixTotal=  prixTotal + 4250;
        resumeStandContent='<div class="c-resume__line"><p>Stand 27m²</p><p>4250€</p></div>'
      }else {
      prixTotal=  prixTotal + 3900;
      resumeStandContent='<div class="c-resume__line"><p>Stand 27m²</p><p>3900€</p></div>'}
    }
    if (selectedStand == 36) {
      if(type=="ALLISCA"){
        prixTotal=  prixTotal + 4500;
        resumeStandContent='<div class="c-resume__line"><p>Stand 36m²</p><p>4500€</p></div>';
      }else {
      prixTotal=  prixTotal + 4300;
      resumeStandContent='<div class="c-resume__line"><p>Stand 36m²</p><p>4300€</p></div>';}
    }

    //meubel
    for (let index1 = 0; index1 < inputQuantityPrix.length; index1++) {
      const element1 = inputQuantityPrix[index1];
      const elementValue = element1.value;
      prixTotal=  prixTotal + (elementValue * parseInt(element1.getAttribute("data-price")));
      if(elementValue>0){
        resumeMeubleContent= resumeMeubleContent + '<div class="c-resume__line"><p>' + elementValue + " " + element1.getAttribute("data-label") + " (" + element1.getAttribute("data-price") + "€ l’unité)" + '</p><p>' + element1.getAttribute("data-price")*elementValue  + '€</p></div>';
      }

    }

    let resumeCautionContent = '<div class="c-resume__line"><p>Caution</p><p>500€</p></div>'

    resumeBox.innerHTML= resumeStandContent + resumeCheckboxContent + resumeMeubleContent + resumeMealContent;

    document.querySelector(".js-prix-option--ttc").innerHTML = ((prixTotal)) + "€";

    let ht = ((prixTotal * 1.2) + 500);
    ht = Math.round(ht * 100) / 100;
    document.querySelector(".js-prix-option--ht").innerHTML = ht + "€";
  }

}
